<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref, computed, watch, onMounted, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import CustomTitle from '@/components/Title';
import DashboardCard from '@/components/DashboardCard';
import Problems from '@/fiduciary/modules/Problems';
import ProductStates from '@/fiduciary/modules/ProductStates';
import DashboardWidgetFeedback from '@/components/DashboardWidgetFeedback';
import DashboardWidgetNewEnvironment from '@/components/DashboardWidgetNewEnvironment';
import DropdownV2 from '@/components/DropdownV2';
import DropdownGroup from '@/components/DropdownGroup';
import DropdownItem from '@/components/DropdownItem';
import Icon from '@/components/Icon';
import { useStore } from 'vuex';
import draggable from 'vuedraggable';

const props = defineProps({
    currentEnvironment: {
        type: Object,
    },
    accountant: Object,
});

const { t } = useI18n();
const store = useStore();

const isDirectCustomer = computed(() => props.accountant.isDirectCustomer);

const isThereLocalDataForEnvironment = !!localStorage.getItem(`dashboard-cards-${props.currentEnvironment.id}`);

// be carefull that slugs CONNOT be changed, as they are used to identify the cards in local storage
const defaultCards = ref([
    { slug: 'welcome', menuTitle: 'dashboard-widget-new-environment.menuTitle', title: 'dashboard-widget-new-environment.title', icon: 'Trophy', iconColor: 'green', iconPulse: true, component: 'DashboardWidgetNewEnvironment', visible: false },
    { slug: 'feedback', menuTitle: 'dashboard-widget-feedback.menuTitle', title: 'dashboard-widget-feedback.title', icon: 'Megaphone', component: 'DashboardWidgetFeedback', visible: true },
    { slug: 'problems-detection', menuTitle: 'dashboard-widget-problems-detection.menuTitle', title: 'dashboard-widget-problems-detection.title', titleInfo: 'dashboard-widget-problems-detection.titleInfo', icon: 'ExclamationTriangle', component: 'Problems', visible: true },
]);

if (!isDirectCustomer.value) {
    defaultCards.value = [
        ...defaultCards.value,
      { slug: 'activation-rate', menuTitle: 'dashboard-widget-activation-rate.menuTitle', title: 'dashboard-widget-activation-rate.title', titleInfo: 'dashboard-widget-activation-rate.titleInfo', icon: 'ChartBar', component: 'ProductStates', visible: true },
    ];
}

const cards = ref([]);

const getLocalStorageKey = () => `dashboard-cards-${props.currentEnvironment.id}`;

const loadCardsFromLocalStorage = () => {
    const savedCards = localStorage.getItem(getLocalStorageKey());
    if (savedCards) {
        const parsedCards = JSON.parse(savedCards);
        cards.value = parsedCards.map(savedCard => {
            const defaultCard = defaultCards.value.find(card => card.slug === savedCard.slug);
            return {
                ...defaultCard,
                size: savedCard.size,
                visible: savedCard.visible,
            };
        });
    } else {
        cards.value = defaultCards.value;
    }
};

onMounted(() => {
    loadCardsFromLocalStorage();
});

watch(() => props.currentEnvironment.id, () => {
    loadCardsFromLocalStorage();
});

watch(cards, (newCards) => {
    const persistentData = newCards.map(card => ({
        slug: card.slug,
        size: card.size,
        visible: card.visible,
    }));
    localStorage.setItem(getLocalStorageKey(), JSON.stringify(persistentData));
}, { deep: true });

const GET_CLIENT = gql`
  query getClient($input: ClientSearchInput) {
    clients(input: $input) {
      results {
        id
      }
    }
  }
`;

// Welcome card displayed only if there are no dossiers
const { result, loading, error, refetch } = useQuery(GET_CLIENT, {
    variables: { input: { fiduciaryId: props.currentEnvironment.id, limit: 1 } },
});

watchEffect(() => {
    if (props.currentEnvironment) {
        refetch({ input: { fiduciaryId: props.currentEnvironment.id, limit: 1 } });
    }
});

watch([result], () => {
    if (!loading.value && !error.value) {
        if (!result.value?.clients?.results?.length > 0 && store.state.flags['new-env'] && !isThereLocalDataForEnvironment) {
            displayCard('welcome');
        }
    } else if (error.value) {
        console.error(error);
    }
});

const removeCard = (slug) => {
    const card = cards.value.find(card => card.slug === slug);
    if (card) {
        card.visible = false;
    }
};

const resizeCard = (slug, size) => {
    const card = cards.value.find(card => card.slug === slug);
    if (card) {
        card.size = size;
    }
};

const toggleCardVisibility = (slug) => {
    const card = cards.value.find(card => card.slug === slug);
    if (card) {
        card.visible = !card.visible;
    }
};

const displayCard = (slug) => {
    const card = cards.value.find(card => card.slug === slug);
    if (card) {
        card.visible = true;
    }
};
</script>

<template>
    <div class='mb-6 dashboard'>
        <div class='mb-6 flex justify-between items-end'>
            <CustomTitle class='mb-0' leading='none'>
                Dashboard
            </CustomTitle>
            <DropdownV2
                class='widget-switch z-50 lg:py-0 font-medium'
                :name='t("dashboard-widget-menu.name")'
                button-small
                big-icon
                icon='Cog6Tooth'
                direction='right'
                button-extra-classes='max-w-xs md:max-w-sm lg:max-w-sm xl:max-w-3xl overflow-hidden overflow-ellipsis'
                menu-extra-classes='w-64 max-w-xs md:max-w-sm lg:max-w-2xl xl:max-w-6xl'
                id='dashboard-widget-switch'
            >
                <DropdownGroup>
                    <DropdownItem
                        v-for='card in defaultCards'
                        :id='card.slug'
                        :key='card.slug'
                        :name='t(card.title)'
                        @click='toggleCardVisibility(card.slug)'
                    >
                        <span :class='{"line-through": !cards.some(c => c.slug === card.slug && c.visible)}' class='w-full'>
                            {{ t(card.menuTitle) }}
                        </span>
                        <div class='relative w-5 h-5 ml-auto group'>
                            <Icon
                                width='w-5'
                                :name='cards.some(c => c.slug === card.slug && c.visible) ? "Eye" : "EyeSlash"'
                                class='text-gray-500 top-0 left-0 absolute hover:gray-700 group-hover:opacity-0'
                            />
                            <Icon
                                width='w-5'
                                :name='cards.some(c => c.slug === card.slug && c.visible) ? "EyeSlash" : "Eye"'
                                class='text-gray-500 top-0 left-0 hover:gray-700 absolute opacity-0 group-hover:opacity-100'
                            />
                        </div>
                    </DropdownItem>
                </DropdownGroup>
            </DropdownV2>
        </div>
        <draggable
            v-model='cards'
            item-key='slug'
            class='grid auto-rows-auto grid-cols-1 lg:grid-cols-6 gap-6'
            ghost-class='dashboard-card__ghost'
            drag-class='dashboard-card__drag'
        >
            <template #item='{ element }'>
                <DashboardCard
                    v-if='element.visible'
                    :title='t(element.title)'
                    :title-info='element.titleInfo ? t(element.titleInfo) : null'
                    :icon='element.icon'
                    :icon-pulse='element.iconPulse'
                    :icon-color='element.iconColor'
                    @closed='removeCard(element.slug)'
                    @resize='resizeCard(element.slug, $event)'
                    :size='element.size'
                    :id='element.slug'
                    class='dashboard-card'
                >
                    <template v-if='element.component === "Problems"'>
                        <Problems :current-environment='props.currentEnvironment' :organization='props.accountant' scrollable />
                    </template>
                    <template v-else-if='element.component === "ProductStates"'>
                        <ProductStates :current-environment='props.currentEnvironment' />
                    </template>
                    <template v-else-if='element.component === "DashboardWidgetFeedback"'>
                        <DashboardWidgetFeedback />
                    </template>
                    <template v-else-if='element.component === "DashboardWidgetNewEnvironment"'>
                        <DashboardWidgetNewEnvironment />
                    </template>
                </DashboardCard>
            </template>
        </draggable>
    </div>
</template>

<style lang='postcss' scoped>
.dashboard-card {
  @apply cursor-pointer;
}

.dashboard-card__drag {
  @apply opacity-100 shadow-lg;
}

.dashboard-card__ghost::after {
  @apply border-2 border-dashed bg-grey-30 absolute inset-0 rounded-md;
  content: '';
  z-index: 40 !important;
}

.masonry {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}

.masonry-item {
  break-inside: avoid;
}
</style>
