<script>
import clickOutside from '@/directives/click-outside';
import Icon from '@/components/Icon.vue';

export default {
    name: 'DropdownV2',
    props: {
        icon: {
            type: String,
            default: 'ChevronDown',
        },
        name: String,
        value: String || Array || Number,
        defaultValue: String,
        modelValue: String || Array || Number,
        nullable: {
            type: Boolean,
            default: false,
        },
        direction: {
            type: String,
            default: 'left',
        },
        buttonSmall: {
            type: Boolean,
            default: false,
        },
        buttonExtraSmall: {
            type: Boolean,
            default: false,
        },
        semiTransparent: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        noSelected: {
            type: Boolean,
            default: false,
        },
        inverted: {
            type: Boolean,
            default: false,
        },
        whiteName: {
            type: Boolean,
            default: false,
        },
        whiteText: {
            type: Boolean,
            default: false,
        },
        accordion: {
            type: Boolean,
            default: false,
        },
        noMargins: {
            type: Boolean,
            default: false,
        },
        buttonExtraClasses: {
            type: String,
            default: () => '',
        },
        menuExtraClasses: {
            type: String,
            default: () => '',
        },
        secondary: {
            type: Boolean,
            default: false,
        },
        split: {
            type: Boolean,
            default: false,
        },
        bigIcon: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        darkMenu: {
            type: Boolean,
            default: false,
        },
        buttonBig: {
            type: Boolean,
            default: false,
        },
        stopPropagation: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        nameInHeader: {
            type: Boolean,
            default: false,
        },
        blueName: {
            type: Boolean,
            default: false,
        },
        greyName: {
            type: Boolean,
            default: false,
        },
        heroIcon: {
            type: String,
        },
    },
    directives: {
        clickOutside,
    },
    components: {
        Icon,
    },
    provide () {
        return {
            change: this.change,
            selected: this.selected,
            options: this.options,
            updateSelectedItem: this.updateSelectedItem,
        };
    },
    data () {
        return {
            menuOpened: false,
            selectedItem: null,
            options: [],
        };
    },
    // VUE-3-MIGRATION-DIRT
    compatConfig: { COMPONENT_V_MODEL: false },
    computed: {
        computedValue () {
            return this.modelValue || this.value;
        },
        selected: {
            get: function () {
                return this.computedValue;
            },
            set: function (newValue) {
                this.updateSelectedItem(newValue);
                this.$emit('update:modelValue', newValue);
                this.$emit('input', newValue);
            },
        },
    },
    watch: {
        modelValue: function () {
            this.updateSelectedItem(this.selected);
        },
        value: function () {
            this.updateSelectedItem(this.selected);
        },
        '$i18n.locale': function  () {
            this.updateSelectedItem(this.selected);
        },
    },
    created () {
        if (this.modelValue || this.value) {
            this.updateSelectedItem(this.computedValue);
        }
    },
    // 'activate' is emitted through the child DropdownItem.vue
    emits: ['update:modelValue', 'input', 'changed', 'activate'],
    methods: {
        updateSelectedItem (value) {
            this.$nextTick(() => {
                this.options.forEach(option => {
                    if (option.id === value) {
                        this.selectedItem = option.name;
                    }
                });
            });
        },
        toggle (e) {
            if (this.disabled) {
                return;
            }
            if (this.stopPropagation) e.stopPropagation();
            this.menuOpened = !this.menuOpened;
        },
        close () {
            this.menuOpened = false;
        },
        change (selected) {
            if (this.selected !== selected) {
                this.$emit('update:modelValue', selected);
                this.$emit('changed', selected);
                this.selected = selected;
            }
            this.close();
        },
        reset () {
            this.change(this.defaultValue ? this.defaultValue : null);
        },
    },
};
</script>

<template>
    <div class='dropdown-v2' v-click-outside='close'>
        <div
            class='dropdown-v2-button'
            :class='[{
                "dropdown-v2-button-small": buttonSmall,
                "dropdown-v2-button-extra-small": buttonExtraSmall,
                "dropdown-v2-button-big": buttonBig,
                "dropdown-v2-button-big-with-header": buttonBig && nameInHeader,
                "semi-transparent": semiTransparent,
                "transparent": transparent,
                "accordion": accordion,
                "noMargins": noMargins,
                "secondary": secondary,
                "opacity-50 cursor-not-allowed": disabled,
                whiteText,
                menuOpened,
                dark,
            }, buttonExtraClasses]'
            @click='toggle'
        >
            <div
                class='flex-grow'
                :class='{
                    "flex": !nameInHeader,
                    "mr-1": (name || selectedItem) && buttonExtraSmall && icon,
                    "mr-3": (name || selectedItem) && !buttonExtraSmall && icon,
                    "mr-0": !(name || selectedItem) && icon,
                }'
            >
                <span v-if='name' :class='{ "text-sm block": nameInHeader, greyName, blueName, whiteName}'>{{ name }}</span>
                <span class='flex items-center' v-if='selected'>
                    <span class='separator mr-2' :class='{ "semi-transparent": semiTransparent }' v-if='!split && name && !noSelected && !nameInHeader'>|</span>
                    <div v-if='!noSelected'>
                        {{ selectedItem }}
                    </div>
                    <div v-if='nullable' class='ml-2 text-grey-300 cursor-pointer hover:text-blue-300' @click='reset'><i class='fa fa-times'></i></div>
                </span>
            </div>
            <span
                class='dropdown-v2-icon -mr-1'
                :class='[{
                    "dropdown-v2-icon__active": menuOpened,
                    "dropdown-v2-icon--upper": icon === "ChevronDown",
                    bigIcon
                }]'
                v-if='icon'
            >
                <Icon :name='icon' width='w-5' />
            </span>
        </div>
        <div
            :class='[
                menuExtraClasses,
                {
                    "dropdown-v2-menu__active": menuOpened,
                    "accordion": accordion,
                    "transparent": transparent,
                    "bg-white" : !darkMenu,
                    "bg-grey-250": darkMenu,
                },
                direction,
            ]'
            class='dropdown-v2-menu'
        >
            <slot></slot>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.dropdown-v2 {
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
}

.dropdown-v2-button {
    @apply border border-solid border-grey-100 bg-grey-100 rounded-lg px-4 text-grey-700 h-12 flex items-center whitespace-nowrap cursor-pointer transition-colors duration-150 ease-linear font-normal;
}

.dropdown-v2-button:hover {
    @apply bg-grey-200;
}

.dropdown-v2-button-small {
    @apply px-3 h-10;
}

.dropdown-v2-button-extra-small {
    @apply px-2 h-6;
}

.dropdown-v2-button-big {
    @apply h-14 px-5;
}

.dropdown-v2-button-big-with-header {
    @apply h-14 px-3;
}

.dropdown-v2-button.whiteText {
    @apply text-white;
}

.dropdown-v2-button .whiteName {
    @apply text-white;
}

.dropdown-v2-button.semi-transparent {
    @apply text-white;
    background-color: rgb(255 255 255 / .1);
    border-color: rgb(255 255 255 / .0);
}

.dropdown-v2-button.transparent {
    background: transparent;
    border-color: transparent;
}

.dropdown-v2-button.dark {
    @apply border-grey-250 bg-grey-250;
}

.dropdown-v2-button.transparent:hover {
    background-color: rgb(255 255 255 / .1);
}

.dropdown-v2-button.transparent.menuOpened {
    background-color: rgb(255 255 255 / .1);
}

.dropdown-v2-button.secondary {
    @apply border border-2 border-blue-400 text-blue-400;
    background: transparent;
}

.dropdown-v2-button.accordion {
    @apply justify-center;
}

.dropdown-v2-button.noMargins {
    margin: 0;
    padding: 0;
}

.dropdown-v2-menu {
    @apply z-50;
    top: calc(100% + 8px);
    transition: all ease-in-out .1s;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    transform: translateY(-5px);
}

.dropdown-v2-menu__active {
    opacity: 1;
    height: auto;
    pointer-events: auto;
    transform: translateY(0);
}

.dropdown-v2-menu.right {
    @apply rounded-lg shadow-lg absolute right-0;
}

.dropdown-v2-menu.left {
    @apply rounded-lg shadow-lg absolute left-0;
}

.dropdown-v2-menu.topcenter {
    @apply rounded-lg shadow-lg absolute top-0;
    right: 50%;
    transform: translate(50%, -100%);
}

.dropdown-v2-menu.accordion {
    @apply bg-transparent shadow-none flex flex-col items-center;
    max-height: auto;
    transition: none;
}

.accordion.dropdown-v2-menu__active {
    position: static;
}

.dropdown-v2-menu.transparent.accordion {
    @apply text-white;
}

.separator {
    @apply ml-2 text-grey-300;
}

.separator.semi-transparent {
    color: rgb(255 255 255 / .3);
}

.dropdown-v2-icon {
    @apply text-xs;
    transition: all ease-in-out .2s;
}

.dropdown-v2-icon.bigIcon {
    @apply text-base ml-2;
}

.dropdown-v2-icon--upper {
    margin-top: -2px;
}

.dropdown-v2-icon__active {
    transform: rotate(180deg);
}

.dropdown-v2-button .blueName {
    @apply text-blue-200;
}

.dropdown-v2-button .greyName {
    @apply text-grey-400;
}
</style>
